* {
    text-rendering: optimizeLegibility;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    -webkit-tap-highlight-color: transparent;
    -webkit-text-size-adjust: 100%;
    transition: all .2s ease;
    box-sizing: border-box;
}

html, body {
    &.noScroll {
        position: relative;
        overflow: hidden;
    }
}

html {
    height: 100%;
    font-size: $rem-baseline;
}

body {
    color: $main-text-color;
    line-height: 1.1;
    font-family: $font-roboto;
}

a {
    color: inherit;
    text-decoration: none;
}

img {
    max-width: 100%;
}

svg {
    max-width: 100%;
    max-height: 100%;
}

@keyframes scrollDownPoint {
    0% {
        transform: translate(0, 0);
        opacity: 0; 
    }
    40% {
        opacity: 1;
    }
    80% {
        transform: translate(0, 20px);
        opacity: 0; 
    }
    100% {
        opacity: 0; 
    } 
}

.wrapper {
    width: 100%;
    max-width: 1100px;
    margin: 0 auto;
    padding-left: 10px;
    padding-right: 10px;
}

#sliderMain {
    min-height: 1080px;

    * {
        transition: none;
    }
}

.rev_slider_wrapper {
    z-index: 10;

    &__title {
        position: relative;
        font-family: $font-roboto-slab;
        font-size: 3.75rem;
        line-height: 1.1;
        text-align: center;
        padding: 0 20px 30px 20px;
        max-width: 100%;
        margin-bottom: 30px;

        &:after {
            position: absolute;
            content: '';
            bottom: 0;
            left: 0;
            right: 0;
            width: 100px;
            height: 4px;
            margin: auto;
            border-radius: 4px;
            background-color: $main-slider-text-color
        }
    }

    &__date {
        font-family: $font-roboto-slab;
        font-size: 2.5rem;
        display: block;
        text-align: center;
    }

    &__text-layer {
        color: $main-slider-text-color !important;
        text-transform: uppercase;
    }

    &__scroll-down {
        position: absolute;
        bottom: 85px;
        left: 0;
        right: 0;
        width: 30px;
        height: 50px;
        border: 2px solid $main-slider-text-color;
        border-radius: 50px;
        margin: auto;
        cursor: pointer;
        z-index: 20;

        &:before {
            position: absolute;
            top: 10px;
            left: 50%;
            content: '';
            width: 6px;
            height: 6px;
            margin-left: -3px;
            background-color: $main-slider-text-color;
            border-radius: 100%;
            animation: scrollDownPoint 2s infinite;
        }
    }

    .tp-caption {
        white-space: inherit !important;
    }

    .tparrows {
        width: 60px;
        background: none;
        transition: all .3s linear;
        display: none;

        &.tp-leftarrow {
            svg {
                transform: rotate(180deg) translateX(-30px);
            }
        }

        &:before {
            display: none;
        }

        svg {
            fill: $main-slider-text-color;
        }
    }

    .defaultimg:before {
        position: absolute;
        content: '';
        width: 100%;
        height: 100%;
        top:0;
        left: 0;
        background-color: rgba(0, 0, 0, 0.25);
    }

    &--homepage .tparrows {
        display: block;
    }
}

.header {
    position: absolute;
    width: 100%;
    z-index: 20;
    bottom:0;
    background-color: rgba(1, 124, 142, 0.85);

    &.fixed {
        position: fixed;
        top: -65px;
        max-height: 65px;
        transform: translateY(100%);
        transition: transform .3s .5s ease-in-out;
    }

    &__inner {
        min-height: 65px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0 50px;
    }

    &__logo {
        display: flex;
        align-items: center;

        &__icon {
            fill: $header-icons-color;

            &--wide {
                width: 155px;
                height: 35px;
            }

            &--tide {
                width: 75px;
                height: 35px;
            }
        }
    }

    &__buttons {
        display: flex;

        &-item {
            width: 80px;
            height: 65px;
            cursor: pointer;
            padding: 20px;
            display: flex;
            align-items: center;

            &:first-of-type {
                background-color: $header-buttons-book-bg-color;
            }

            &:last-of-type {
                padding-left: 24px;
                padding-right: 24px;
            }

            svg {
                width: 100%;
                height: 100%;
                fill: $header-icons-color;
                transition-duration: .1s;
            }
        }
    }

    &__menu {
        position: fixed;
        top: 0;
        width: 100%;
        height: 100vh;
        min-height: 100vh;
        background-color: rgba(255, 255, 255, 0.95);
        visibility: hidden;
        overflow-y:auto;
        opacity: 0;
        z-index: 20;

        &.active {
            opacity: 1;
            visibility: visible;

            .main-nav__item {
                opacity: 1;
            }
        }

        &-close {
            position: absolute;
            top: 0;
            right: 0;
            width: 50px;
            height: 50px;
            padding: 13px;
            background-color: $header-menu-close-button-bg-color;
            fill: $header-icons-color;
            cursor: pointer;
        }

        &-language {
            margin-top: 50px;
        }   

        &-nav {
            margin-top: 110px;
        }

        &-subnav {
            margin: 100px 0 40px 0;
        }
    }
}

.menu-language {
    display: flex;
    align-items: center;
    justify-content: center;

    &__item {
        width: 75px;
        height: 75px;
        border-radius: 50%;
        border: 2px solid $header-menu-navigation-color;
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 0 15px;
        font-size: 1.75rem;
        font-family: $font-roboto-slab;
        font-weight: 700;
        color: $header-menu-navigation-color;

        &.active {
            color: $header-icons-color;
            border-color: $header-menu-navigation-akcent-color;
            background-color: $header-menu-navigation-akcent-color;
        }
    }
}

.main-nav {
    display: flex;
    flex-flow: column;
    align-items: center;
    padding: 0 25px;

    &__item {
        font-family: $font-roboto-slab;
        margin-top: 15px;
        text-align: center;

        &:nth-child(2) {
            opacity: 0;
            transition: 0.3s opacity 200ms;
        }

        &:nth-child(3) {
            opacity: 0;
            transition: 0.3s opacity 400ms;
        }

        &:nth-child(4) {
            opacity: 0;
            transition: 0.3s opacity 600ms;
        }

        input {
            display: none;

            &:checked {
                & + span {
                    color: $header-main-nav-active-color;

                    i {

                        &:before {
                            background-color: $header-main-nav-active-color;
                        }

                        &:after {
                            opacity: 0;
                        }
                    }
                }

                & ~ div {
                    max-height: 300px;
                }
            }
        }
    }

    &__label {
        color: $header-main-nav-color;
        text-transform: uppercase;
        text-align: center;
        font-size: 2.75rem;
        display: inline-flex;
        align-items: center;
        transition: none;
    }

    &__expand {
        position: relative;
        display: inline-block;
        width: 30px;
        height: 30px;
        cursor: pointer;
        margin-left: 10px;

        &:before, &:after {
            position: absolute;
            content: '';
            background-color: $header-main-nav-color;
            transition: opacity .3s ease;
        }

        &:before {
            width: 100%;
            height: 3px;
            left: 0;
            top: 50%;
            transform: translateY(-50%);
        }

        &:after {
            width: 3px;
            height: 100%;
            left: 50%;
            transform: translateX(-50%);
        }
    }

    &__hidden-menu {
        max-height: 0;
        overflow: hidden;
        transition: max-Height .5s ease;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-flow: row wrap;

        &__item {
            font-size: 1.25rem;
            color: $header-main-nav-hidden-menu-color;
            margin: 15px;
        }
    }
}

.main-subnav {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-flow: row wrap;
    padding: 0 25px;

    &__item {
        color: $header-main-subnav-color;
        text-transform: uppercase;
        font-family: $font-roboto-slab;
        font-size: 1.625rem;
        margin: 10px 15px;
        padding: 0 5px;
    }
}

.breadcrumbs {
    background-color: $breadcrumbs-bg-color;
    padding: 15px 0;

    .wrapper {
        text-align: center;
    }

    &__item {
        position: relative;
        margin: 0 10px;
        font-family: $font-roboto-slab;
        font-weight: 300;

        &.active {
            color: $breadcrumbs-link-hover-color;
        }

        &:last-child:after {
            display: none;
        }

        &:after {
            position: absolute;
            content: '';
            width: 4px;
            height: 4px;
            border-radius: 50%;
            top: 50%;
            right: -13px;
            transform:translateY(-50%);
            background-color: $breadcrumbs-separator-bg-color;
        }
    }
}

.btn {
    padding: 25px 50px;
    text-transform: uppercase;
    font-family: $font-roboto-slab;
    font-size: 1.875rem;
    background-color: $btn-main-bg-color;
    display: inline-block;
    border: none;
    cursor: pointer;
    outline: none;
    color: #fff;
    width: 27%;
    min-width: 170px;
    border-radius: 0;
    -webkit-appearance: none;
}

.paralax-lines {
    position: absolute;
    border-radius: 4px;
    background-color: $main-paralax-text-color;
    z-index: 2;

    &--horizontal {

        &-top {
            right: -25px;
            width: 430px;
            height: 4px;

            top: 50px;
            right: 25px;
        }

        &-bottom {
            width: 335px;
            height: 4px;
            left: -25px;
            bottom: 50px;

            left: 25px;
        }
    }

    &--vertical {

        &-top {
            width: 4px;
            height: 280px;
            right: 0;
            top: 35px;

            right: 50px;
        }

        &-bottom {
            left: 0;
            width: 4px;
            height: 240px;

            bottom: 35px;
            left: 50px;
        }
    }

    &--promotions-horizontal {
        width: 500px;
        height: 4px;
        bottom: 35px;
        right: -60px;
    }

    &--promotions-vertical {
        width: 4px;
        height: 250px;
        bottom: 15px;
        right: 30px;
    }
}

.welcome {
    background-color: $welcome-bg-color;
    padding: 90px 0 110px 0;

    &__top {
        max-width: 890px;
        margin: 0 auto;
        display: flex;
        align-items: flex-end;
    }

    &__texts {
        font-family: $font-roboto-slab;
        font-weight: 700;
        text-transform: uppercase;

        &__title1 {
            font-weight: 300;
            color: $welcome-text-main-color;
            font-size: 3.75rem;
            margin-bottom: 40px;
            text-transform: initial;
        }

        &__title2 {
            font-size: 2rem;
            margin-left: 90px;
        }

        &__title3 {
            font-size: 4.375rem;
            margin-left: 50px;
        }
    }

    &__logo {
        padding-left: 150px;
        margin-bottom: 10px;
    }

    &__description {
        font-size: $paragraph-font-size;
        line-height: $paragraph-line-height;
        margin-top: 40px;

        a {
            color: $welcome-links-color;
        }
    }
}

.main-paralax {
    position: relative;
    height: 600px;
    background-attachment: fixed;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    -webkit-backface-visibility: hidden;
    overflow: hidden;
    padding: 40px 70px;

    @supports (-webkit-overflow-scrolling: touch) {
      background-attachment: scroll;
    }

    &:before {
        position: absolute;
        content: '';
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        background-color: rgba(0,0,0, 0.2);
    }

    &__link {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 1;
    }

    &__info {
        position: relative;
        text-align: right;
        color: $main-paralax-text-color;
    }

    &__icon {
        width: 65px;
        height: 65px;
        fill: $main-paralax-text-color;
        margin: 30px 40px 20px 0;
    }

    &__title, &__subtitle {
        font-family: $font-roboto-slab;
        text-transform: uppercase;
    }

    &__title {
        font-size: 2.5rem;
        font-weight: 700;
        margin-bottom: 10px;
    }

    &__subtitle {
        font-size: 2rem;
    }
}

.activities {
    color: $activities-text-color;
    padding: 60px 0 70px 0;

    &__title {
        text-align: center;
        text-transform: uppercase;
        font-size: 2rem;
        font-family: $font-roboto-slab;
        margin-bottom: 25px;

        span {
            position: relative;
            color: $main-theme-color;
            font-size: 3.375rem;
            padding: 0 5px;
            bottom: -5px;
        }
    }

    &__types {
        display: flex;
        align-items: center;
        justify-content: center;
    }

    &__item {
        margin: 0 15px;
        width: 65px;
        height: 65px;
        fill: $activities-icons-color;
    }
}

.promotions {
    padding-top: 30px;
    font-family: $font-roboto-slab;
    font-size: 1.125rem;

    & + & {
        margin-top: 50px;
    }

    &--dark {
        background-color: $promotions-dark-bg-color;
    }

    &--light {
        background-color: $promotions-light-bg-color;

        .promotions__type {
            color: $promotions-light-type-color;
        }
    }

    &--image-right {

        .promotions {

            &__type {
                text-align: left;
            }

            &__image {
                order: 2;
            }

            &__date {
                left: 55px;
                right: auto;
            }
        }

        .paralax-lines {

            &--promotions-horizontal {
                left: -60px;
                right: auto;
            }

            &--promotions-vertical {
                left: 30px;
                right: auto;
            }
        }
    }

    &__type {
        text-align: right;
        padding: 0 50px;
        font-size: 1.25rem;
        color: $promotions-dark-type-color;
        display: block;
    }

    &__holder {
        position: relative;
        display: flex;
        align-items: center;
    }

    &__image {
        position: relative;
        width: 55%;

        &-link {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background-color: rgba(0,0,0, 0.2);
            transition: opacity 400ms ease-in-out;
            opacity: 0;
            z-index: 1;
        }

        img {
            display: block;
        }
    }

    &__text {
        width: 45%;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    &__text-container {
        width: 100%;
        max-width: 400px;
        text-align: center;
    }

    &__title {
        font-size: 2.25rem;
        text-transform: uppercase;
        color: $promotions-price-color;
    }

    &__description {
        margin-top: 20px;
        font-family: $font-roboto;
        line-height: 1.5;
    }

    &__price {
        margin-top: 30px;
        color: $promotions-price-color;

        span {
            font-size: 2.25rem;
        }
    }

    &__date {
        position: absolute;
        right: 55px;
        bottom: 40px;
        font-size: 1.25rem;

        span {
            font-size: 4rem;
            display: block;
        }
    }
}

.home-information {
    padding-top: 45px;
    padding-bottom: 80px;

    &__image {
        padding-right: 85px;
        margin-bottom: 35px;
        text-align: right;
    }

    &__text {
        color: $home-information-text-color;
        font-size: $paragraph-font-size;
        line-height: 1.6;

        a {
            color: $home-information-link-color;
            text-decoration: underline;
        }
    }
}

.offers {
    margin-bottom: 60px;

    &--no-slider {
        
        .offers {

            &__holder {
                display: flex;
                flex-flow: row wrap;
            }

            &__item {
                width: calc(33.33% - 40px);
                margin-top: 50px;
            }
        }
    }

    &--gallery {
        .offers__image a {
            opacity: 0;
        }
    }

    &__filter {
        display: flex;
        align-items: center;
        justify-content: flex-start;  
        flex-flow: row wrap;  
        margin: 70px 0 20px 0;
    
        &-item {
            color: $inner-page-filters-color;
            fill: $inner-page-filters-color;
            font-family: $font-roboto-slab;
            font-size: 2.25rem;
            margin: 0 30px;

            .offers__nav-item {
                margin: 0;
            }
            
            svg {
                fill: $inner-page-filters-color;
            }

            &.active {
                color: $inner-page-filters-hover-color;
            }   
        }
    }
    
    &__holder {
        margin-left: -40px;
        width: calc(100% + 40px);
    }

    &__item {
        background-color: $main-theme-color;
        text-align: center;
        margin-left: 40px;
        padding: 8px 0;
        height: auto;

        &--dark {
            background-color: $promotions-dark-bg-color;
        }
    }

    &__image {
        position: relative;

        a { 
            position: absolute;
            top: 0;
            left: 0;
            display: block;
            width: 100%;
            height: 100%;
            opacity: 0;
            background-color: rgba(0, 0, 0, 0.2);
        }

        img {
            vertical-align:bottom;
        }
    }

    &__info {
        padding: 25px 45px;
    }

    &__type {
        font-size: 1.125rem;
        opacity: 0.55;
    }

    &__title {
        font-size: 1.75rem;
        font-family: $font-roboto-slab;
        line-height: 1.3;
        margin-top: 25px;
        color: $promotions-price-color;
        text-transform: uppercase;
    }

    &__desc {
        margin-top: 20px ;
        font-size: $paragraph-font-size;
        line-height: $paragraph-line-height;
    }

    &__price {
        margin-top: 25px;
        color: $promotions-price-color;
        
        span {
            font-size: 2.0625rem;
        }
    }

    &__date {
        margin-top: 25px;
        display: inline-block;
        font-size: 1.125rem;

        span {
            font-size: 3.4375rem;
            display: block;
        }
    }

    &__nav {
        text-align: center;

        &--mt-50 {
            margin-top: 50px;
        }

        &-item {
            width: 75px;
            height: 30px;
            margin: 0 20px;
            fill: #00a6bd;
            cursor: pointer;

            &--prev {
                transform: rotate(180deg);
            }
        }
    }

    .slick-track {
        display: flex;
    }
}

.inner-page {
    background-color: $rooms-bg-color;
    padding-top: 75px;

    &__wrapper {
        position: relative;
    }
    
    &__background {
        position: absolute;
        width: 500px;
        height: 300px;
        top: -50px;
        right: 50px;
        fill: $rooms-background-icon;
        pointer-events: none;
    }

    &__nav {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0 60px 0 120px;
    }

    &__type {
        color: $rooms-nav-color;
        font-family: $font-roboto-slab;
        font-size: 1.625rem;
    }

    &__info {
        padding-bottom: 75px;
    }

    &__arrows {
        z-index: 1;

        svg {
            fill: $rooms-nav-color;
        } 
    }

    &__title {
        position: relative;
        z-index: 1;
        max-width: 750px;
        margin: 60px auto 0 auto;
        font-family: $font-roboto-slab;
        font-size: 3.125rem;
        font-weight: 700;
    }

    &__descr {
        position: relative;
        margin-top: 50px;
        font-size: $paragraph-font-size;
        line-height: $paragraph-line-height;

        a {
            color: $rooms-descr-links-color;
            text-decoration: underline;
        }

        p {
            margin-bottom: 20px;
        }
        
        h2, h3, h4, h5 {
            font-family: $font-roboto-slab;
            font-weight: 700;
        }

        h2 {
            color: $inner-page-heading-color;
            font-size: 2rem;
            margin-bottom: 10px;
        }

        h3 {
            font-size: 1.75rem;
            margin-bottom: 10px;
        }

        h4 {
            font-size: 2.25rem;
            color: $inner-page-heading-color;
            text-transform: uppercase;
        }

    }
}

.rooms {

    &__bottom {
        background: $rooms-bottom-info-bg-color;
        padding: 40px 0;
        font-family: $font-roboto-slab;

        .wrapper {
            display: flex;
            align-items: center;
            justify-content: space-between;
        }
    }

    &__price {
        margin-top: 0;
        white-space: nowrap;
    }

    &__dates {
        display: flex;
        align-items: center;
    }

    &__date {
        font-size: 1.125rem;
        padding:0 30px;
        text-align: center;

        &:first-child {
            border-right: 2px solid #fff;
        }

        span {
            font-size: 3.75rem;
            margin-top: -5px;
            display: block;
        }
    }

    &__buttons-holder {
        display: flex;
        align-items: center;
    }

    &__button {
        width: 150px;
        height: 120px;
        border: 2px solid $rooms-button-border-color;
        padding: 25px;
        text-transform: uppercase;

        &-icon {
            display: flex;
            align-items: center;
            justify-content: center;
        }

        &-text {
            text-align: center;
            display: block;
            margin-top: 10px;
            font-family: $font-roboto-slab;
            font-weight: 700;
            color: $rooms-button-panorama-text-color;
        }

        svg {
            width: 50px;
            height: 40px;
            fill: $main-icons-default-color;
        }

        &--book {
            margin-left: 70px;
            border-color: $rooms-button-book-akcent-color;

            .rooms__button-text {
                color: $rooms-button-book-akcent-color;
            }
        }

        &--panorama svg {
            width: 70px;
        }
    }
}

.contacts {
    margin-top: 70px;

    &__title {
        font-family: $font-roboto-slab;
        font-size: 2.5rem;
        color: $contacts-text-title-color;
        text-transform: uppercase;
    }

    &__title, &__form {
        max-width: 760px;
        margin-left: auto;
        margin-right: auto;
    }

    &__form {
        margin-top: 70px;
        max-width: 790px;
    }

    &__text {
        margin-top: 70px;
        color: $contacts-text-title-color;
        font-size: $paragraph-font-size;
        line-height: $paragraph-line-height;

        a {
            color: $home-information-link-color;
            text-decoration: underline;
        }
    }

    &__buttons {
        text-align: center;
        margin-top: 80px;
    }
}

.google-map {
    height: 500px;
    margin-top: 80px;
}

.form {

    &__row {
        margin-top: 15px;
        font-family: $font-roboto-slab;
        font-size: 0.9375rem;

        &--two-columns {
            display: flex;
            justify-content: space-between;
        }

        &--center {
            align-items: center;
        }

        &--mt-40 {
            margin-top: 40px;
        }

        &--no-margin {
            margin: 0;
        }
    }

    &__col {
        padding: 0 15px;

        &-50{ 
            width: 50%;
        }
    }

    &__label {
        font-family: $font-roboto-slab;
        color: $form-label-color;
        margin-bottom: 10px;
        display: block;
    }

    &__input {
        padding: 10px 15px;
    }

    &__textarea {
        padding: 15px;
        min-height: 180px;
        resize: vertical;
    }

    &__input, &__textarea {
        display: block;
        width: 100%;
        border: 1px solid $form-element-border-color;
        border-radius: 0;
        background:none;
        color: $form-element-color;
    }

    &__checkbox {
        display: none;

        & + .form__label {
            position: relative;
            padding-left: 45px;
            cursor: pointer;
            display: flex;
            align-items: center;
            min-height: 30px;
            
            &:before {
                position: absolute;
                content: '';
                left: 0;
                width: 25px;
                height: 25px;
                border: 1px solid $form-element-border-color;
            }

            &:after {
                position: absolute;
                content: '\2714';
                left: 8px;
                top: 7px;
                display: none;
            }
        }   

        &:checked + .form__label:after {
            display: block;
        }
    }
}

.footer {
    margin-top: 70px;
    background-color: $footer-bg-color;
    font-family: $font-roboto-slab;

    &__top {
        padding: 60px 0 70px 0;

        .wrapper {
            display: flex;
            align-items: center;
        }
    }

    &__info {
        flex: 1 0 500px;
        padding-right: 120px;
        font-size: 1.25rem;
        line-height: 1.6;
        border-right: 2px solid $footer-top-divider-color;

        figure {
            max-width: 270px;
            margin-bottom: 30px;
        }
    }

    &__navigation {
        width: 100%;
        display: flex;
        justify-content: space-between;
        padding-left: 100px;

        &-item {
            color: $footer-top-nav-color;
            text-transform: uppercase;
            display: block;
             
            & + & {
                margin-top: 20px;
            }
        }
    }

    &__bottom {
        padding: 20px 0;
        background-color: $footer-bottom-bg-color;

        .wrapper {
            display: flex;
            align-items: center;
            justify-content: space-between;
        }
    }

    &__allrights {
        color: $footer-bottom-text-color;
        padding-right: 20px;

        a {
            color: $footer-bottom-link-color;
        }
    }

    &__social {
        display: flex;
        align-items: center;
        justify-content: center;

        &-item {
            width: 35px;
            height: 25px;
            margin: 0 5px;

            svg {
                fill: $footer-social-color;
            }
        }
    }
}