@media (min-width: map-get($grid-breakpoints, tablet) ) {
	.rev_slider_wrapper .tp-caption {
		min-width: 1000px !important;
	}

	.breadcrumbs__item:hover {
		color: $breadcrumbs-link-hover-color;
	}

	.header__buttons-item:hover {
		background-color: $header-buttons-book--hover-bg-color;

        svg {
            fill: $main-theme-color;
        }
	}

	.menu-language__item:hover {
		color: $header-icons-color;
        border-color: $header-menu-navigation-akcent-color;
        background-color: $header-menu-navigation-akcent-color;
	}
	
	.main-nav {

		&__hidden-menu__item:hover {
        	color: $header-main-nav-hidden-menu-hover-color;
		}

		&__label:hover {
			color: $header-main-nav-active-color;

			i:before, i:after {
				background-color: $header-main-nav-active-color;
			}
		}
	}

	.main-subnav__item:hover {
        text-decoration: underline;
	}

	.welcome {

		&__texts__title1 a:hover {
			color: $welcome-title-link-hover;
		}

		&__description a:hover {
	        color: $welcome-links-hover-color;
		}
	}

	.promotions {
		&__image-link:hover {
			opacity: 1;
		}

		&__type, &__title {
			&:hover {
				color: $promotions-type-title-hover-color;
			}
		}
	}

	.home-information__text a:hover {
		color: $home-information-link-hover-color;
		text-decoration: none;
	}

	.contacts__text a:hover {
		color: $home-information-link-hover-color;
		text-decoration: none;
	}

	.footer {

		&__navigation-item:hover {
			color: $footer-top-nav-hover-color;
		}

		&__social-item:hover svg {
			fill: #fff;
		}

		&__allrights a:hover {
			color: #fff;
		}
	}

	.offers {
		&__image:hover a {
			opacity: 1;
		}

	 	&__type:hover {
			opacity: 1;
		}

		&__title:hover {
			color: $promotions-type-title-hover-color;
		}

		&__filter-item:hover {
			color: $inner-page-filters-hover-color;

			svg {
				fill: $inner-page-filters-hover-color;
			}
		}

		&__nav-item:hover {
            fill: $main-theme-color;
		}

		&--gallery .offers__image a:hover {
			opacity: 1;
		}
	}

	.rooms {
		&__button {

			&:hover {
				background-color: $rooms-button-panorama-hover-bg-color;

				.rooms__button-text {
					color: $rooms-nav-hover-color;
				}
			}

			&--book:hover {
				background-color: $rooms-button-book-akcent-color;

				.rooms__button-text {
					color: $rooms-nav-hover-color;
				}
			}
		}
	}

	.inner-page {
		&__type:hover {
			color: $rooms-nav-hover-color;
		}

		&__arrows svg:hover {
	        fill: $rooms-nav-hover-color
		}

		&__descr a:hover {
			color: $rooms-descr-links-hover-color;
			text-decoration: none;
		}
	}

	.btn:hover {
		background-color: $main-theme-color;
	}
}

@media (max-width: map-get($grid-breakpoints, tablet)) {
	html {
		font-size: 15px;
	}

	.rev_slider_wrapper .tp-caption {
		min-width: 750px !important;
	}

	.header__inner {
		padding: 0 0 0 20px;
	}

	.paralax-lines {

		&--horizontal {
			
			&-top {
				right: 15px;
			}

			&-bottom {
				left: 15px;;
			}
		}

		&--vertical {
			
			&-top {
				right: 25px;
			}

			&-bottom {
				left: 25px;
			}
		}
	}

	.promotions {

		&__text-container {
			padding-top: 15px;
		}

		&__date {
			position: static;
			display: block;
			margin: 10px 0 25px 0;
		}
	}

	.offers {

		&__info {
			padding-left: 35px;
			padding-right: 35p;
		}

		&__title {
			font-size: 22px;
		}

		&__filter {
			justify-content: center;
		}
	}

	.footer__navigation {
		padding: 0 50px;
	}

	.form {

		&__input, &__textarea {
        	-webkit-appearance: none;
		}
	}
}


@media (max-width: map-get($grid-breakpoints, lg) ) {

	.breadcrumbs__item {
		margin: 3px 10px;
		display: inline-block;
	}

	.wrapper {
		padding-left: 20px;
		padding-right: 20px;
	}

	.welcome__logo {
		padding-left: 50px;
	}

	.main-nav {
		
		&__label {
			font-size: 32px;
		}
	}

	.menu-language {

		&__item {
			width: 65px;
			height: 65px;
			font-size: 24px;
		}
	}

	.inner-page {

		&__background {
			width: 350px;
		}

		&__nav {
			padding: 0;
		}

		&__title {
			font-size: 2.25rem;
		}
	}
	
	.main-paralax {
		padding-left: 55px;
		padding-right: 55px;
	}

	.rooms {

		&__date {

			span {
				font-size: 48px;
			}
		}

		&__button {
			width: 120px;
			height: 100px;
			padding: 10px;

			&--book {
				margin-left: 20px;
			}
		}
	}
	
	.promotions {
		
		&__text-container {
			padding: 15px 20px;
		}

		&__title {
			font-size: 24px;
		}

		&__description {
			display: none;
		}

		&__date span {
			font-size: 40px;
		}

		&--image-right .paralax-lines--promotions-horizontal {
			left: -20px;
		}
	}

	.paralax-lines--promotions {
		
		&-horizontal {
			width: 300px;
			right: -20px;
		}

		&-vertical {
			height: 200px;
		}
	}

	.offers {

		&__filter-item {
			font-size: 25px;
			margin: 0 20px;
		}
		
		&--no-slider {
			.offers__item {
				width: calc(50% - 40px);
			}
		}
	}

	.footer {

		&__info {
			padding-right: 20px;
			flex: 1 0 350px;
		}
	}
}

@media (max-width: map-get($grid-breakpoints, md) ) {
	
	.rev_slider_wrapper {

		&__title {
			font-size: 30px;
		}

		&__date {
			font-size: 26px;
		}

		&__scroll-down {
			bottom: 60px;
		}

		.tparrows {
			width: 50px;
			opacity: 1 !important;
			visibility: visible !important;
			top: auto !important;
			bottom: 130px;

			transform: translateX(-50%) !important;
			left: 50% !important;
			
			&.tp-leftarrow {
				margin-left: -50px;

				svg {
					transform: rotate(180deg) !important;
				}
			}

			&.tp-rightarrow {
				margin-left: 50px;
				
			}
		}

		.tp-caption {
			min-width: 300px !important;
		}
	}

	.header {

		&.fixed {
			top: -50px;
			max-height: 50px;
		}

		&__inner {
			min-height: 50px;
		}

		&__logo {
			max-width: 150px;

			&__icon--tide {
				width: 60px;
				margin-left: 5px;
			}
		}

		&__buttons-item {
			width: 60px;
			height: 50px;
			padding: 15px;

			&:last-of-type {
				padding-left: 20px;
				padding-right: 20px;
			}
		}

		&__menu-nav {
			margin-top: 90px;
		}

		&__menu-subnav {
			margin-top: 90px;
		}
	}

	.inner-page {
		padding-top: 40px;

		&__nav {
			flex-flow: row wrap;

			.offers__nav-item--prev {
				margin-left: 0;
			}
		}

		&__arrows {
			margin-top: 20px;
		}

		&__background {
			width: 200px;
		}

		&__info {
			padding-bottom: 40px;
		}

		&__type {
			font-size: 16px;
		}

		&__title {
			font-size: 20px;
			margin-top: 20px;
		}

		&__descr {
			font-size: 15px;
			margin-top: 20px;

			h2 {
				font-size: 18px;
			}

			h3 {
				font-size: 17px;
			}

			h4 {
				font-size: 19px;
			}
		}
	}

	.main-nav {
		
		&__label {
			font-size: 22px;
		}

		&__expand {
			width: 15px;
			height: 15px;
		}
		
		&__hidden-menu__item {
			font-size: 16px;
			margin: 10px 8px;
		}
	}

	.main-subnav__item {
		font-size: 16px;
	}

	.menu-language {

		&__item {
			width: 50px;
			height: 50px;
			font-size: 18px;
		}
	}

	.welcome {
		padding: 50px 0 45px 0;

		&__top {
			display: block;
		}

		&__logo {
			padding-left: 0;
			max-width: 90px;
			margin: 25px auto 0 auto;
		}
	
		&__texts {
			&__title1 {
				font-size: 28px;
				margin-bottom: 20px;
			}

			&__title2 {
				margin-left: 50px;
				font-size: 15px;
			}

			&__title3 {
				text-align: center;
				font-size: 32px;
				margin-left: 0;
			}
		}

		&__description {
			margin-top: 25px;
			font-size: 15px;
		}
	}

	.paralax-lines {
		display: none;
	}

	.main-paralax {
		padding-left: 20px;
		padding-right: 20px;

		&__info {
			text-align: center;
		}

		&__icon {
			margin-top: 0;
			margin-right: 0;
		}

		&__title {
			font-size: 30px;
			margin-bottom: 15px;
		}

		&__subtitle {
			font-size: 24px;
		}
	}

	.activities {
		padding: 45px 0 40px 0;

		&__title {
			font-size: 23px;

			span {
				display: block;
				font-size: 37px;
				bottom: 0;
			}
		}

		&__item {
			margin:0 10px;
			width: 50px;
			height: 40px;
		}
	}

	.promotions {
		padding-top: 15px;

		& + & {
			margin-top: 45px;
		}

		&--image-right {

			.promotions {
				&__type {
					text-align: center;
				}
				
				&__text {
					order:2;
				}
			}
		}

		&__type {
			text-align: center;
			margin-bottom: 15px;
			font-size: 16px;
		}

		&__title {
			font-size: 20px;
		}

		&__description {
			font-size: 15px;
		}

		&__holder {
			flex-flow: column;
		}

		&__image {
			width: 100%;
		}

		&__text {
			width: 100%;

			&-container {
				padding-top: 25px;
				padding-bottom: 25px;
			}
		}

		&__description {
			display: block;
		}

		&__price {
			margin-top: 25px;
			font-size: 15px;

			span {
				font-size: 28px;
			}
		}

		&__date {
			margin: 25px 0 15px 0;
			font-size: 16px;

			span {
				font-size: 50px;
			}
		}
	}

	.home-information {
		padding-top: 25px;
		padding-bottom: 55px; 

		&__image {
			padding-right: 40px;

			img {
				max-width: 150px;
			}
		}

		&__text {
			font-size: 15px;
		}
	}

	.offers {
		margin-bottom: 40px;

		&--no-slider {
			.offers__item {
				width: calc(100% - 40px);
			}
		}

		&__nav--mt-50 {
			margin-top: 35px;
		}

		&__filter {
			margin: 40px 0 0 0;

			&-item {
				font-size: 18px;
				margin: 0 5px;

				&:first-of-type {
					margin-left: 0;
				}

				&:last-of-type {
					margin-right: 0;
				}

				.offers__nav-item {
					width: 35px;
				}
			}
		}

		&__info {
			padding-left: 20px;
			padding-right: 20px;
		}

		&__desc {
			font-size: 15px;
		}

		&__type {
			font-size: 16px;
		}

		&__title {
			font-size: 20px;
		}

		&__price {
			font-size: 15px;

			span {
				font-size: 28px;
			}
		}

		&__date {
			font-size: 15px;

			span {
				font-size: 50px;
			}
		}

		&__nav-item {
			width: 55px;
			margin: 0 30px;
		}	
	}

	.rooms {

		&__bottom .wrapper {
			flex-flow: column;
		}

		&__price {
			margin: 0 0 30px 0;
		}

		&__buttons-holder {
			margin-top: 30px;
		}

		&__button svg {
			width: 40px;
		}

		&__date span {
			font-size: 32px;
		}
	}

	.gallery {
		margin-bottom: 40px;
	}

	.footer {

		&__top {
			padding: 30px 0 50px 0;
		}

		&__info {
			flex: 1 1 100%;
			border-right: none;
			padding-right: 0;
			font-size: 15px;

			figure {
				max-width: 225px;
				margin: 0 auto 30px auto;
			}

			div {
				text-align: center;
			}
		}

		&__navigation {
			display: none;
		}

		&__bottom {
			padding: 35px 0;

			.wrapper {
				flex-flow: column;
			}
		} 

		&__social-item {
			width: 40px;
			height: 30px;
			margin: 0 10px;
		}

		&__allrights {
			margin-top: 40px;
			padding-right: 0;
			order: 2;
			font-size: 13px;
			text-align: center;

			span {
				display: block;
			}
		}
	}

	.contacts {
		margin-top: 40px;

		&__title {
			font-size: 2rem;
		}

		&__form {
			margin-top: 30px;
		}

		&__text {
			margin-top: 30px;
		}

		&__buttons {
			margin-top: 40px;
		}
	}

	.google-map {
		margin-top: 40px;
	}

	.form {

		&__row {

			&--two-columns {
				display: block;
			}
		}

		&__col {
			padding: 0;

			&-50 {
				width: 100%;

				& + & {
					margin-top: 15px;
				}
			}
		}
	}

	.btn {
		width: 100%;
		padding: 20px;
		font-size: 1.5rem;
	}
}