$font-roboto: 'Roboto', sans-serif;
$font-roboto-slab: 'Roboto Slab', serif;

$main-theme-color: #017c8e;
$main-text-color: #fefefe;
$main-icons-default-color: #fff;

$main-slider-text-color: #fff;

$header-icons-color: #fff;
$header-buttons-book-bg-color: #004853;
$header-buttons-book--hover-bg-color: #fff;
$header-menu-close-button-bg-color: #5d483d;
$header-menu-navigation-color: #5d483d;
$header-menu-navigation-akcent-color: #01a6bd;
$header-main-subnav-color: #5d483d;
$header-main-nav-color: #5d483d;
$header-main-nav-active-color: #01a6bd;
$header-main-nav-hidden-menu-color: #01a6bd;
$header-main-nav-hidden-menu-hover-color: #006b7b;

$welcome-bg-color: $main-theme-color;
$welcome-text-main-color: #ffcf97;
$welcome-links-color: #ffd59a;
$welcome-links-hover-color: #ffc36d;
$welcome-title-link-hover: #fff;

$activities-text-color: #6f5a4e;
$activities-icons-color: #ae9f97;

$main-paralax-text-color: #ffffff;

$promotions-dark-bg-color: #5d483d;
$promotions-light-bg-color: #1a5e68;
$promotions-dark-type-color: #c2aea3;
$promotions-light-type-color: #78bec8;
$promotions-type-title-hover-color: #fff;
$promotions-price-color: #ffcf97;

$home-information-text-color: #6f5a4e;
$home-information-link-color: #e87d00;
$home-information-link-hover-color: #e06400;

$breadcrumbs-bg-color: #004853;
$breadcrumbs-separator-bg-color: #fbc46a;
$breadcrumbs-link-hover-color: #ffc36d;

$rooms-bg-color: #8e786b;
$rooms-background-icon: #7d685d;
$rooms-nav-color: #ffcf97;
$rooms-nav-hover-color: #fff;
$rooms-descr-links-color: #ffd59a;
$rooms-descr-links-hover-color: #ffc36d;
$rooms-bottom-info-bg-color: #4a382e;
$rooms-button-border-color: #98857b;
$rooms-button-book-akcent-color: #20bdd3;
$rooms-button-panorama-text-color: #c2aea3;
$rooms-button-panorama-hover-bg-color: #8e786c;

$inner-page-heading-color: #ffd59a;
$inner-page-filters-color: #ae9f97;
$inner-page-filters-hover-color: #6f5a4e;

$contacts-text-title-color: #6f5a4e;

$footer-bg-color: #4a372c;
$footer-top-divider-color: #322218;
$footer-top-nav-color: #e6d0c2;
$footer-top-nav-hover-color: #6af3ff;

$footer-bottom-bg-color: #322218;
$footer-bottom-text-color: #a08c80;
$footer-bottom-link-color: #d9cec6;
$footer-social-color: #00a6bd;

$btn-main-bg-color: #01a6bd;

$form-label-color: #017c8e;
$form-element-border-color: #bfd6d9;
$form-element-color: #004f5a;

$grid-breakpoints: (
    sm: 480px,
    md: 767px,
    lg: 992px,
    tablet: 1024px,
    xl: 1200px
);

$rem-baseline: 16px;

$paragraph-font-size: 1.0625rem;
$paragraph-line-height: 1.55;
